.content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px 0;
  align-items: center;
}

.rankings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 20px 0;
  max-width: 100%;
}

.weights {
  display: inline-block;
  text-align: center;
}