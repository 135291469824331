.records-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  align-items: center;
}

.row-input {
  max-width: 300px;
  display: flex;
  gap: 20px;
}

.table-content {
  min-width: 50%;
}

.table-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
