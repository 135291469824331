.standings-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px 0;
  align-items: center;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 80%;
  min-width: 50%;
  gap: 40px;
}

.year-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  width: 50%;
}

.table-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;
  max-width: 100%;
}

.legend {
  display: flex;
  gap: 15px;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: '#283447';
  height: 80px;
  padding: 0 10px;
  border-radius: 7px;
  border: 1px solid white;
}

.legend-champ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: gold;
  color: black;
  height: 40px;
  width: 90px;
}

.legend-reg-season-champ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: 'inherit';
  color: white;
  border: 4px solid lightgreen;
  height: 40px;
  width: 90px;
}

/* .table-container {
  max-width: 80%;
  color: white;
  background-color: '#283447';
  border: 1px solid white;
  border-radius: 3;
} */