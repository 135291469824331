.wrapper {
  margin-top: 200px;
}

.row-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.league-info-subtitles {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
