.baseball-container {
  display: flex;
  flex-direction: column;
  padding: 5px 0px 0px 0px;
  justify-content: center;
  max-width: 100vw;
}

.baseball-spinner {
  padding-top: 200px;
}

.baseball-radio-group {
  padding-bottom: 10px;
}

.baseball-table-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;
  background-color: white;
  color: black;
  border-spacing: 0 !important;
  height: 85vh;
}

@media (max-width: 1100px) {
  .baseball-table-container {
    justify-content: start;
  }
  .baseball-clip {
    display: none;
  }  
}

.baseball-table {
  border-spacing: 0;
  overflow-y: auto;
  font-size: 14px;
}

.baseball-table td {
  border: 1px solid #d3d3d3;
  padding: 4px 6px;
  border-spacing: 0;
}

.baseball-table th {
  position: sticky;
  background-color: #d3d3d3;
  padding: 6px;
  font-size: 13px;
  top: 0;
}

.baseball-clickable {
  cursor: pointer;
  white-space: nowrap;
}

.baseball-gray {
  background-color: #d3d3d3;
}

.baseball-row-number {
  color: gray;
  font-size: 12px;
}

.baseball-left {
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
}

.baseball-right {
  display: inline-block;
  padding: 2px 2px 0px 4px;
}

.up-arrow {
  width: 0;
  height: 0;
  border: solid 4px transparent;
  background: transparent;
  border-bottom: solid 6px #a0a0a0;
  border-top-width: 0;
  cursor: pointer;
}

.down-arrow {
  width: 0;
  height: 0;
  border: solid 4px transparent;
  background: transparent;
  border-top: solid 6px #a0a0a0;
  border-bottom-width: 0;
  margin-top:2px;
  cursor: pointer;
}

.up-black {
  border-bottom: solid 5px black !important;
}

.down-black {
  border-top: solid 5px black !important;
}
